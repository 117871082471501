<template>
  <div>
    <div v-if="showLoading"><Loading /></div>
    <div class="page">
      <div class="page__title">手機驗證</div>
      <div class="page__desc">
        簡訊驗證碼已發送至<b>{{ mobile }}</b
        >，<br />
        驗證碼將於 <b>{{ timeout.minute }}</b> 分
        <b>{{ timeout.second }}</b> 秒後失效
      </div>

      <div class="code-input">
        <CodeInput
          :loading="false"
          :fields="6"
          :fieldHeight="92"
          :class="{ invalid: !isValid }"
          @change="onChange"
        />
        <div v-if="!isValid" class="form__error-msg">
          驗證碼有誤，請再次確認
        </div>
      </div>

      <div class="page__bottom">
        沒收到嗎？<span @click="handleResend">重新發送驗證碼</span>
      </div>
    </div>
    <div class="page__button">
      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="handleSubmit"
      >
        送出
      </SharedButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import CodeInput from "vue-verification-code-input";
import staffActiveApi from "@/apis/liff/v2/staffActive";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import axios from "axios";
const TIMEOUT = 120;

export default {
  components: {
    CodeInput,
    Loading,
    SharedButton,
  },

  data() {
    return {
      showLoading: false,
      verificationCode: null,
      counter: TIMEOUT,
      timer: null,
      isValid: true,
      payload: {}
    };
  },

  computed: {
    timeout() {
      return {
        minute: Math.floor(this.counter / 60),
        second: Math.floor(this.counter % 60),
      };
    },
    mobile() {
      const mobile = this.$store.state.liffAuth.user.mobile;
      return mobile
        ? mobile.substring(0, 3) + "****" + mobile.substring(7, 10)
        : null;
    },
  },

  beforeDestroy() {
    this.clearTimer();
  },

  mounted() {
    this.startTimer();
    this.getPayloadFromQuery();
  },

  methods: {
    getPayloadFromQuery() {
      this.payload = this.$route.query;
    },

    onChange(e) {
      this.verificationCode = e;
    },

    async handleResend() {
      this.showLoading = true;

      try {
        await staffActiveApi.resendPhone(this.payload);
        this.restartTimer();
      } catch (error) {
        console.log("error", error);
      }

      this.showLoading = false;
    },

    async handleSubmit() {
      this.showLoading = true;

      try {
        let payload = this.payload
        payload.code = this.verificationCode
        await staffActiveApi.verifyPhone(payload);

        const url =
          process.env.VUE_APP_API_BASE_URL +
          "/" +
          this.$route.params.orgCode +
          "/liff/staff_active";

        await axios
          .post(url, this.payload)
          .then((response) => {
            if (response.data.status === "success") {
              this.$router.push({
                name: "LiffStaffActiveSuccess",
                params: {
                  orgCode: this.$route.params.orgCode,
                },
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
            if (
              error.response.data.status === "failed" &&
              error.response.data.message
            ) {
              alert(error.response.data.message);
            }
          });
      } catch (error) {
        console.log("error", error);
        this.isValid = false;
      }

      this.showLoading = false;
    },

    startTimer() {
      this.timer = window.setInterval(() => {
        this.counter--;

        if (this.counter > 0) {
          return;
        }

        this.clearTimer();
      }, 1000);
    },
    clearTimer() {
      if (!this.timer) {
        return;
      }

      window.clearInterval(this.timer);
      this.timer = null;
    },
    restartTimer() {
      this.clearTimer();
      this.counter = TIMEOUT;
      this.startTimer();
    },
  },
};
</script>

<style lang="scss" scoped>
.form__error-msg {
  color: #fe0000;
  font-size: 12px;
  margin-top: 8px;
  margin-left: calc((100% - 60px * 5 - 8px * 4) / 2);
}
.code-input {
  margin-bottom: 32px;
  ::v-deep .react-code-input-container {
    width: 100% !important;
    text-align: center;
    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366;
    border-radius: 10px !important;
    font-size: 50px;
    font-weight: 600;
    width: 60px;
    font-family: PingFang TC;
    color: var(--liff-primary_text_color);

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
.page__bottom {
  font-size: 14px;
  line-height: 22px;
  color: var(--liff-secondary_text_color);
  text-align: center;
  span {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
